<template>
  <div class="materialsApply">
    <div class="content">
      <!-- 顶部 -->
      <Header :titleName="'选择城市'" :return="true" />
      





    </div>

  </div>
</template>

<script setup>

import { reactive, onMounted } from "vue";
import Api from "../../utils/api";
import { useRouter, useRoute } from 'vue-router'
import Header from "../../components/Header.vue";
const route = useRoute()
const router = useRouter()

let data = reactive({

  id: 0,
  detail: null,
});

onMounted(() => {

  data.id = route.query.id
  console.log(data.id)
  Api.materialproject(data.id).then(res => {
    if (res.status == 200) {
      data.detail = res.data
      console.log(res)
    }
  })
});

</script>

<style lang='less' scoped>
.materialsApply {
  display: flex;
  flex-flow: column;
  height: 100%;

  .content {
    flex: 1;
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
    padding-bottom: 2.2667rem;
    background-color: #fff;

  }
}
</style>